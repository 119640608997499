import React from "react";

const Card = props => {
  const {
    cardImgClass,
    cardImgSrc,
    cardImgAlt,
    cardText,
    cardTextClass,
    cardTitleClass,
    cardTitle
  } = props;
  return (
    <div className="col-10 col-sm-10 col-md-4 d-flex flex-column justify-content-start card-container-py">
      <div>
        <img className={cardImgClass} src={cardImgSrc} alt={cardImgAlt} />
      </div>
      {cardTitle && <h3 className={cardTitleClass}>{cardTitle}</h3>}
      <p className={cardTextClass}>{cardText}</p>
      {props.children}
    </div>
  );
};

export default Card;
