import React from "react";
import { withRouter } from "react-router-dom";
import "./Services.css";
import ExtraServicesCabinets from "../../assets/images/extras_services_cabinets.svg";
import ExtraServicesFridge from "../../assets/images/extras_services_fridge.svg";
import ExtraServicesOven from "../../assets/images/extras_services_oven.svg";
import ExtraServicesBlinds from "../../assets/images/extras_services_blinds.svg";
import RoomServices from "../../assets/content/room_services_lists.json";
import RoomServicesCard from "../../components/RoomServicesCard/RoomServicesCard";
import ServicesProducts from "../../assets/images/services_products.jpg";
import ScrollToTop from "../../helpers/ScrollToTop";

class Services extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicCleaning: true,
      deepCleaning: false,
      moveCleaning: false
    };
  }

  handleCleaningSelection = e => {
    const { name } = e.target;
    let cleanings = {
      basicCleaning: false,
      deepCleaning: false,
      moveCleaning: false
    };
    cleanings[name] = !cleanings[name];
    this.setState({ ...cleanings });
  };

  setCleaningSelection = name => {
    let cleanings = {
      basicCleaning: false,
      deepCleaning: false,
      moveCleaning: false
    };
    cleanings[name] = !cleanings[name];
    this.setState({ ...cleanings });
  };

  renderRoomServices = () => {
    const { basicCleaning, deepCleaning } = this.state;
    if (basicCleaning) {
      return (
        <React.Fragment>
          {RoomServices["basic-cleaning"].map((areas, i) => {
            return (
              <RoomServicesCard
                cardClassName="col-11 col-sm-9 col-md-6 col-lg-5 px-3 mt-5"
                cardTitleContainerClassName="col-8 px-4 py-2"
                cardTitleTextClassName="p-0 m-0"
                titleText={areas.room}
                cardBodyContainerClassName="col-12 p-3"
              >
                <ul className="px-4 py-2">
                  {areas.list.map(e => {
                    return <li className="py-1">{e}</li>;
                  })}
                </ul>
              </RoomServicesCard>
            );
          })}
        </React.Fragment>
      );
    } else if (deepCleaning) {
      return (
        <React.Fragment>
          {RoomServices["deep-cleaning"].map((areas, i) => {
            return (
              <RoomServicesCard
                cardClassName="col-11 col-sm-9 col-md-6 col-lg-5 px-3 mt-5"
                cardTitleContainerClassName="col-9 px-4 py-2"
                cardTitleTextClassName="p-0 m-0"
                titleText={areas.room}
                cardBodyContainerClassName="col-12 p-3"
              >
                <ul className="px-4 py-2">
                  {areas.list.map(e => {
                    return <li className="py-1">{e}</li>;
                  })}
                </ul>
              </RoomServicesCard>
            );
          })}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {RoomServices["move-cleaning"].map((areas, i) => {
            return (
              <RoomServicesCard
                cardClassName="col-11 col-sm-9 col-md-6 col-lg-5 px-3 mt-5"
                cardTitleContainerClassName="col-9 px-4 py-2"
                cardTitleTextClassName="p-0 m-0"
                titleText={areas.room}
                cardBodyContainerClassName="col-12 p-3"
              >
                <ul className="px-4 py-2">
                  {areas.list.map(e => {
                    return <li className="py-1">{e}</li>;
                  })}
                </ul>
              </RoomServicesCard>
            );
          })}
        </React.Fragment>
      );
    }
  };

  componentDidMount() {
    this.props.history.location.state &&
      this.setCleaningSelection(
        this.props.history.location.state["cleaning-type"]
      );
  }

  render() {
    let { basicCleaning, deepCleaning, moveCleaning } = this.state;
    return (
      <div>
        <ScrollToTop />
        <div className="container mt-5">
          <div>
            <h2 className="font-weight-bold">Our Services</h2>
            <div className="col-12 d-flex flex-wrap px-0 mt-4 ">
              <div className="col-12 col-sm-6 py-3 px-4">
                <h3 className="font-weight-bold">Our methods</h3>
                <p className="our-methods-text p-font-size">
                  We pride ourselves on being experts in sophisticated
                  eco-friendly cleaning services. As trained professionals and
                  responsible business owners, we are ready to transform your
                  home using organic products we customize with essential oils.
                </p>
              </div>
              <div className="col-12 col-sm-6 py-3 px-4 our-products-container-border">
                <h3 className="font-weight-bold">Our products</h3>
                <p className="our-products-text p-font-size">
                  We care for the health of our customers specially because many
                  homes have kids or pets, we provide green products for all our
                  cleanings
                </p>
              </div>
            </div>
            <div
              id="details"
              className="col-12 row mx-0 justify-content-center mt-4"
            >
              <div
                className="btn-group"
                role="group"
                aria-label="Cleaning Types"
              >
                <button
                  type="button"
                  name="basicCleaning"
                  className={`btn ${
                    basicCleaning ? "btn-success" : "btn-outline-success"
                  }`}
                  onClick={this.handleCleaningSelection}
                >
                  Basic Cleaning
                </button>
                <button
                  type="button"
                  name="deepCleaning"
                  className={`btn ${
                    deepCleaning ? "btn-success" : "btn-outline-success"
                  }`}
                  onClick={this.handleCleaningSelection}
                >
                  Deep Cleaning
                </button>
                <button
                  type="button"
                  name="moveCleaning"
                  className={`btn ${
                    moveCleaning ? "btn-success" : "btn-outline-success"
                  }`}
                  onClick={this.handleCleaningSelection}
                >
                  Move in/out Cleaning
                </button>
              </div>
            </div>
            <div className="row justify-content-around">
              {/*cards will go here */
              this.renderRoomServices()}
            </div>
            <RoomServicesCard
              cardClassName="col-12 mt-5"
              cardTitleContainerClassName="col-6 col-lg-4 px-4 py-2"
              cardTitleTextClassName="p-0 m-0"
              titleText="Extras"
              cardBodyContainerClassName="col-12 p-3 justify-content-center mx-0 row card-body-container-height"
            >
              <div className="d-flex py-3 flex-column col-10 col-md-3 col-lg-3 justify-content-end align-items-center">
                <img src={ExtraServicesFridge} alt="Inside Fridge" />
                <p className="py-1 mb-0">Inside Fridge</p>
                <p>$35</p>
              </div>
              <div className="d-flex py-3 flex-column col-10 col-md-3 col-lg-3 justify-content-end align-items-center">
                <img src={ExtraServicesCabinets} alt="Inside Cabinets" />
                <p className="py-1 mb-0">Inside Cabinets</p>
                <p>$35</p>
              </div>
              <div className="d-flex py-3 flex-column col-10 col-md-3 col-lg-3 justify-content-end align-items-center">
                <img src={ExtraServicesOven} alt="Oven" />
                <p className="py-1 mb-0">Oven</p>
                <p>$35</p>
              </div>
              <div className="d-flex py-3 flex-column col-10 col-md-3 col-lg-3 justify-content-end align-items-center">
                <img src={ExtraServicesBlinds} alt="Blinds" />
                <p className="py-1 mb-0">Blinds</p>
                <p>$35</p>
              </div>
            </RoomServicesCard>
            <div className="py-5 col-12 d-flex flex-column align-items-center">
              <h3 className="font-weight-bold p-2">The products we use</h3>
              <img
                className="col-10 col-md-5 p-0 rounded-lg cleaning-products-img"
                src={ServicesProducts}
                alt="Cleaning Supplies"
              />
              <p className="mb-0 p-4 p-font-size">
                Dr Bronner Soap • Baking Soda • Alcohol • Vinegar • Borax •
                Essential Oils
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Services);
