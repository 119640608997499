import React from "react";
import { Link } from "react-router-dom";
import "../Navbar/Navbar.css";

class Navbar extends React.Component {
  render() {
    return (
      <header>
        <nav className="navbar navbar-expand-lg nav-container">
          <Link className="navbar-brand navbar-brand-size" to="/">
            Apple Eco Cleaning
          </Link>
          <button
            className="custom-toggler navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="collapse navbar-collapse justify-content-end"
            id="navbarNavAltMarkup"
          >
            <div className="navbar-nav justify-content-end navbar-links-container">
              <Link className="nav-item nav-link" to="/">
                Home
              </Link>
              <Link className="nav-item nav-link" to="/services">
                Services
              </Link>
              <Link className="nav-item nav-link" to="/aboutus">
                About us
              </Link>
              <Link className="nav-item nav-link" to="/book">
                Book
              </Link>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default Navbar;
