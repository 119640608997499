import React from "react";
import TeamPhoto from "../../assets/images/team-copy.jpg";
import ScrollToTop from "../../helpers/ScrollToTop";
import Logo from "../../assets/images/aec-logo.png";
import "./AboutUs.css";

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <ScrollToTop />
        <div className="container mt-5">
          <h2 className="font-weight-bold">Our story</h2>
          <div className="col-12 px-0 d-flex flex-column justify-content-center align-items-center">
            <img
              className="col-10 col-md-3 about-logo-img"
              src={Logo}
              alt="Logo"
            />
            <p className="about-text-alignment py-3 p-font-size">
              Apple Eco Cleaning was founded in 2012 with the goal of empowering
              women who work as day laborers in New York City. AEC has served as
              a platform for its members to become business owners, and advance
              as leaders in their communities.
            </p>
          </div>
          <div className="col-12 px-0 about-products-banner-container mt-4">
            <img
              className="col-12 px-0 about-products-banner-img"
              src={TeamPhoto}
              alt="Products Banner"
            />
          </div>
          <div className="row justify-content-around pt-5 mb-5">
            <div className="col-10 col-md-5 d-flex flex-column align-items-center">
              <h3 className="font-weight-bold">Mission</h3>
              <p className="p-font-size">
                We want to build a launching platform for our members to achieve
                their entrepreneurial goals, as well as our services to make a
                positive impact in taking care of the environment.
              </p>
            </div>
            <div className="col-10 col-md-5 d-flex flex-column align-items-center">
              <h3 className="font-weight-bold">Vision</h3>
              <p className="p-font-size">
                To help workers become business owners and leaders in their
                communities, provide high quality cleaning services and take
                care of our client’s health and the environment through eco
                friendly cleaning practices.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
