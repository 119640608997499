import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Home from "./containers/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Services from "./containers/Services/Services";
import AboutUs from "./containers/AboutUs/AboutUs";
import Book from "./containers/Book/Book";
import Footer from "./components/Footer/Footer";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/services" exact component={Services} />
          <Route path="/aboutus" exact component={AboutUs} />
          <Route path="/book" exact component={Book} />
          {/*<Route path='/show/:id' exact component={Show} />
  <Route path='/shows' exact component={Shows} />*/}
        </Switch>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
