import React from "react";
import useScript from "../../hooks/useScript";
import ScrollToTop from "../../helpers/ScrollToTop";
import "./Book.css";

const Book = props => {
  return (
    <div>
      <ScrollToTop />
      <div className="container">
        <div className="d-flex flex-wrap py-5">
          <div className="col-10 col-sm-10 col-md-8 col-lg-7 schedule-appointment-text d-flex flex-wrap">
            <h2 className="font-weight-bold col-12 px-0">
              Schedule Appointment
            </h2>
            <p className="col-10 px-0 mb-0 p-font-size">
              The prices shown are estimates, if you need an estimate in person
              send us an email or give us a call to schedule a visit to your
              home.
            </p>
          </div>
          <div className="col-10 col-sm-10 col-md-4 col-lg-5 schedule-appointment-text d-flex flex-wrap contact-mobile-spacing">
            <h2 className="font-weight-bold col-12 px-0">Contact Us</h2>
            <p className="p-font-size mb-0 col-12 px-0">
              Email:{" "}
              <a href="mailto:info@appleecocleaning.com">
                info@appleecocleaning.com
              </a>
            </p>
            <p className="p-font-size mb-0 col-12 px-0">
              Phone: <a href="tel:347-796-7570">(347) 796-7570</a>
            </p>
          </div>
        </div>
        <iframe
          title="form"
          src="https://services.cognitoforms.com/f/3ofb5ay4u0asTpx-MAVLaw?id=7"
          frameBorder="0"
          scrolling="yes"
          seamless="seamless"
          height="1198"
          width="100%"
        ></iframe>
        {useScript("https://services.cognitoforms.com/scripts/embed.js")}
      </div>
    </div>
  );
};

export default Book;
