import React from "react";
import "./RoomServicesCard.css";

const RoomServicesCard = props => {
  return (
    <div className={props.cardClassName}>
      <div
        className={`room-services-card-title-container ${props.cardTitleContainerClassName}`}
      >
        <h4 className={props.cardTitleTextClassName}>{props.titleText}</h4>
      </div>
      <div
        className={`room-services-card-body-container ${props.cardBodyContainerClassName}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default RoomServicesCard;
