import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Footer = props => {
  return (
    <div className="row justify-content-center align-items-center mt-4 mx-0 footer-container">
      <div className="col-12 px-0 d-flex flex-column align-items-center">
        <h2 className="text-white text-center p-3">Schedule Appointment</h2>
        <Link
          to="/book"
          className="btn btn-outline-success btn-lg footer-book-btn-text"
        >
          Book now
        </Link>
        <div className="col-4 py-3 d-flex justify-content-center text-white">
          <div>
            <a
              className="px-2"
              style={{ color: "white", textDecoration: "underline" }}
              href="https://www.instagram.com/appleecocleaning.coop/"
            >
              Instagram
            </a>

            <a
              className="px-2"
              style={{ color: "white", textDecoration: "underline" }}
              href="https://www.yelp.com/biz/apple-eco-cleaning-new-york"
            >
              Yelp
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
