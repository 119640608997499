import React from "react";
import { Link } from "react-router-dom";
import HomeApartment from "../../assets/images/home-apartment.jpg";
import SaveTime from "../../assets/images/save_time.svg";
import EcoFriendly from "../../assets/images/eco_friendly.svg";
import Collaboration from "../../assets/images/collaboration.svg";
import Cleaning1 from "../../assets/images/cleaning1.jpg";
import Cleaning2 from "../../assets/images/cleaning2.jpg";
import Cleaning3 from "../../assets/images/cleaning3.jpg";
import { HashLink } from "react-router-hash-link";
import Card from "../../components/Card/Card";
import ScrollToTop from "../../helpers/ScrollToTop";
import "./Home.css";

class Home extends React.Component {
  render() {
    return (
      <div>
        <ScrollToTop />
        <div
          style={{
            height: window.innerHeight,
            backgroundImage: `url(${HomeApartment})`,
            backgroundSize: `cover`
          }}
        >
          <div
            className="mx-0 d-flex flex-wrap justify-content-center align-items-center banner-background-dark-layer"
            style={{
              height: window.innerHeight
            }}
          >
            <div className="col-12 d-flex flex-column justify-content-center align-items-center mr-0 banner-header-container">
              <h1 className="text-center col-sm-11 col-md-11 col-lg-10 col-xl-8 m-0 py-4 banner-header-text">
                Home cleaning that supports a movement
              </h1>
              <Link
                className="btn btn-success btn-lg py-2 px-3 banner-header-button"
                to="/book"
              >
                Book Now
              </Link>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="mt-4 py-3">
            <hr />
            <h1 className="py-4 home-headers-font-weigth">Our services</h1>
            <div className="col px-0">
              <div className="pb-2 row justify-content-center">
                <Card
                  cardImgSrc={Cleaning1}
                  cardImgAlt="Basic Cleaning"
                  cardImgClass="col-10 col-md-12 col-xl-10 px-0 services-card-img-radious services-card-img-shadow"
                  cardTitle="Basic Cleaning"
                  cardTitleClass="services-card-title font-weight-bold"
                  cardText="Perfect if you usually keep your home tidy but still need professional help, it is also adequate if you switching to us from another service."
                  cardTextClass="services-card-text p-font-size services-card-text-mt services-card-text-pb "
                >
                  <div>
                    <HashLink
                      className="btn btn-lg btn-outline-success"
                      to={{
                        pathname: "/services",
                        hash: "#details",
                        state: { "cleaning-type": "basicCleaning" }
                      }}
                    >
                      Learn more
                    </HashLink>
                  </div>
                </Card>
                <Card
                  cardImgSrc={Cleaning2}
                  cardImgAlt="Deep Cleaning"
                  cardImgClass="col-10 col-md-12  col-xl-10 px-0 services-card-img-radious services-card-img-shadow"
                  cardTitle="Deep Cleaning"
                  cardTitleClass="services-card-title font-weight-bold"
                  cardText="A more detailed cleaning, recommended if you have never used a professional cleaning service or the space is not cleaned regularly"
                  cardTextClass="services-card-text p-font-size services-card-text-mt services-card-text-pb"
                >
                  <div>
                    <HashLink
                      className="btn btn-lg btn-outline-success"
                      to={{
                        pathname: "/services",
                        hash: "#details",
                        state: { "cleaning-type": "deepCleaning" }
                      }}
                    >
                      Learn more
                    </HashLink>
                  </div>
                </Card>
                <Card
                  cardImgSrc={Cleaning3}
                  cardImgAlt="Move in/out Cleaning"
                  cardImgClass="col-10 col-md-12 col-xl-10 px-0 services-card-img-radious services-card-img-shadow"
                  cardTitle="Move in/out Cleaning"
                  cardTitleClass="services-card-title font-weight-bold"
                  cardText="If you are moving out and need to leave your old space cleaned or if you want your new space to have a thorough cleaning before you move in"
                  cardTextClass="services-card-text p-font-size"
                >
                  <div>
                    <HashLink
                      className="btn btn-lg btn-outline-success"
                      to={{
                        pathname: "/services",
                        hash: "#details",
                        state: { "cleaning-type": "deepCleaning" }
                      }}
                    >
                      Learn more
                    </HashLink>
                  </div>
                </Card>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="container pb-5">
            <hr />
            <h1 className="py-5 home-headers-font-weigth">Why us?</h1>
            <div className="row justify-content-around">
              <Card
                cardImgSrc={SaveTime}
                cardImgAlt="Save Time"
                cardImgClass="col-8 col-md-10 col-lg-6 px-0"
                cardText="If you want your home nice and tidy you will have to spend hours a week cleaning, let us give you back that time. Sit back and relax, while we make your home shine"
                cardTextClass="services-card-text p-font-size"
              />
              <Card
                cardImgSrc={EcoFriendly}
                cardImgAlt="Eco Friendly"
                cardImgClass="col-8 col-md-10 col-lg-6 px-0"
                cardText="Taking care of you and the environment We provide the cleaning products for each cleaning. They are non-toxic and environmental friendly"
                cardTextClass="services-card-text services-card-text-pb p-font-size"
              />
              <Card
                cardImgSrc={Collaboration}
                cardImgAlt="Empowering"
                cardImgClass="col-8 col-md-10 col-lg-6 px-0"
                cardText="You help us empower more people.
                We do not have contractors, all of our members have a stake in the company so when you choose us there is no single person pocketing all the money."
                cardTextClass="services-card-text  p-font-size"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
